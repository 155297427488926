import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Layout from '../layouts/Layout';
import FirstView from '@/components/organisms/common/FirstView';
import ContactLinkBlock from '@/components/organisms/common/ContactLinkBlock';
import BottomMenu from '@/components/organisms/common/BottomMenu';
import Seo from '@/components/organisms/common/Seo';

const FirstViewImage = (theme: Theme) => css({
  height: '25vh',
  [theme.breakpoints.down('xs')]: {
    height: '20vh',
  }
});

const NotFoundBox = (theme: Theme) => css({
  backgroundColor: theme.palette.backgroundDark.default,
  padding: '2rem 0'
});

const TextStyles = (theme: Theme) => css({
  color: '#ffffff',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
    lineHeight: 2,
  }
});

const ContactPage: React.VFC<PageProps> = () => {
  return (
    <Layout>
      <Seo />
      <FirstView
        titleEn="Not Found"
        title="ページが見つかりません"
        titlePosition="right"
      >
        <StaticImage
          alt="notFoundImage"
          src="../images/firstviews/notFound.jpg"
          css={FirstViewImage}
          layout="fullWidth"
          objectPosition="50% 60%"
        />
      </FirstView>
      <section css={NotFoundBox}>
        <Container maxWidth="md">
          <Grid container>
            <Typography css={TextStyles}>
              お探しのページが見つかりません。
              <br />
              URLをご確認の上、再度お越しください。
            </Typography>
          </Grid>
        </Container>
      </section>
      <ContactLinkBlock />
      <BottomMenu />
    </Layout>
  );
};

export default ContactPage;

